import { useState, createContext } from 'react'

export const LeadModalContext = createContext()

export function LeadModalProvider({ children }) {
  const [leadModalContext, setLeadModalContext] = useState({})

  return (
    <LeadModalContext.Provider
      value={{ ...leadModalContext, setLeadModalContext }}
    >
      {children}
    </LeadModalContext.Provider>
  )
}
