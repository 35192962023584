import React from 'react'
import { Global } from '@emotion/core'

export const GlobalStyles = () => (
  <Global
    styles={({ colors }) => ({
      html: {
        marginLeft: 'calc(100vw - 100%)',
      },
      body: {
        height: '100%',
        margin: 0,
        WebkitFontSmoothing: 'antialiased',
        WebkitTapHighlightColor: 'transparent',
        TouchAction: 'manipulation',
      },
      '&::selection': {
        backgroundColor: colors.selection,
      },
      '.CookieConsentButtons': {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'flex-end',
        '#rcc-confirm-button': {
          order: 1,
        },
      },
    })}
  />
)

export default GlobalStyles
