import React, { useEffect, useState } from 'react'

import { Box } from 'components'

const Animate = (props) => {
  const {
    show,

    showAnimation = 'none',
    hideAnimation = 'none',

    onAnimationStart = () => {},
    onAnimationEnd = () => {},

    animationSpeed = 150,

    children,
  } = props
  const [shouldRender, setRender] = useState(show)

  useEffect(() => {
    if (show) setRender(true)
  }, [show])

  const handleAnimationEnd = () => {
    if (!show) {
      setRender(false)
      onAnimationEnd()
    }
  }

  return (
    shouldRender && (
      <Box
        sx={{
          animation: `${
            show ? showAnimation : hideAnimation
          } ${animationSpeed}ms`,
        }}
        onAnimationStart={onAnimationStart}
        onAnimationEnd={handleAnimationEnd}
      >
        {children}
      </Box>
    )
  )
}

export default Animate
