import React, { useEffect, useState } from 'react'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import loadable from '@loadable/component'

import { Box } from 'components'
import Animate from 'components/Animate'

import useOnClickOutside from 'hooks/useOnClickOutside'
import useWindowSize from 'hooks/useWindowSize'

import ToggleComponent from './components/Toggle'

import { fadeSlideIn, fadeSlideOut } from './keyframes'
const WidgetComponent = loadable(() => import('./components/Widget'))

const DEBUG = false

const Positioner = ({ children }) => {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        zIndex: 5000,
        pointerEvents: 'none',
        border: DEBUG ? '2px solid green' : 'none',
      }}
    >
      {children}
    </Box>
  )
}

const Toggle = (props) => {
  return (
    <Box
      sx={{
        marginTop: 150,
        pointerEvents: 'all',
        ...(DEBUG && {
          width: 50,
          height: 50,
          bg: 'blue',
          border: '2px solid red',
        }),
      }}
      {...props}
    >
      <ToggleComponent attention />
    </Box>
  )
}

const Widget = ({ onClose }) => {
  const scrollContainerRef = React.useRef()
  const widgetRef = React.useRef()
  const { width: windowWidth, height: windowHeight } = useWindowSize()

  useOnClickOutside(widgetRef, onClose)

  useEffect(() => {
    const isMobile = windowWidth < 640

    if (isMobile && scrollContainerRef.current) {
      disableBodyScroll(scrollContainerRef.current)
    }

    return () => {
      if (isMobile) {
        clearAllBodyScrollLocks()
      }
    }
  }, [windowWidth])

  return (
    <Box
      ref={widgetRef}
      sx={{
        marginTop: [0, 150],
        display: 'flex',
        flexDirection: 'column',
        width: ['100vw', 320],
        height: [`${windowHeight}px`, 'auto'],
        bg: DEBUG ? 'yellow' : 'transparent',
        pointerEvents: 'all',
      }}
    >
      <WidgetComponent ref={scrollContainerRef} onClose={onClose} />
    </Box>
  )
}

const Container = () => {
  const [toggleVisible, setToggleVisible] = useState(false)
  const [widgetVisible, setWidgetVisible] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setToggleVisible(true)
    }, 3000)
  }, [])

  return (
    <Positioner>
      <Animate
        show={toggleVisible}
        animationSpeed={500}
        showAnimation={fadeSlideIn}
        hideAnimation={fadeSlideOut}
        onAnimationEnd={() => setWidgetVisible(true)}
      >
        {/* <Toggle onClick={() => setToggleVisible(false)} /> */}
        <Toggle
          onClick={() => {
            window.location.hash = 'anmeldenTeilverkaufModal'
          }}
        />
      </Animate>
      <Animate
        show={widgetVisible}
        animationSpeed={500}
        showAnimation={fadeSlideIn}
        hideAnimation={fadeSlideOut}
        onAnimationEnd={() => setToggleVisible(true)}
      >
        <Widget onClose={() => setWidgetVisible(false)} />
      </Animate>
    </Positioner>
  )
}

export default Container
