/*eslint-disable */
export const API_URL = REACT_APP_API_URL
export const LOGIN_URL = REACT_APP_LOGIN_URL
export const CONSENT_COOKIE_DOMAIN = REACT_APP_CONSENT_COOKIE_DOMAIN

export const GTM_ID = REACT_APP_GTM
export const GTM_AUTH = REACT_APP_GTM_AUTH
export const GTM_PREVIEW = REACT_APP_GTM_PREVIEW

export const PARTNER_FORM_URL = REACT_APP_PARTNER_FORM_URL

export const FISHER_SCRIPT_URL = REACT_APP_FISHER_SCRIPT_URL
export const FISHER_WIDGET_DE_API_KEY = REACT_APP_FISHER_WIDGET_DE_API_KEY
export const FISHER_WIDGET_AT_API_KEY = REACT_APP_FISHER_WIDGET_AT_API_KEY

export const GIT_BRANCH = REACT_APP_GIT_BRANCH

export const REACT_APP_IS_TV_CALCULATOR_ENABLED =
  REACT_APP_IS_TV_CALCULATOR_ENABLED
/*eslint-enable */
