import React, { useEffect } from 'react'
import { ThemeProvider } from 'emotion-theming'
import debounce from 'lodash/debounce'

import { isBrowser } from 'utils'

import theme from 'theme'
import GlobalStyles from 'globalStyles'

import { SharedStateProvider, useSharedState } from 'context/sharedState'
import { LocationProvider } from 'context/location'
import { LeadModalProvider } from 'context/LeadModalContext'

import { Root } from 'components'
import Widget from 'components/Widget'

if (isBrowser) {
  require('smooth-scroll')('a[href*="#"]')
}

const vhFix = debounce(() => {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  const vh = window.innerHeight * 0.01
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}, 250)

const Provider = ({ children }) => {
  React.useEffect(() => {
    isBrowser && window.addEventListener('resize', vhFix)
    vhFix()

    return () => {
      isBrowser && window.removeEventListener('resize', vhFix)
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <LocationProvider>
        <SharedStateProvider>
          <LeadModalProvider>
            <GlobalStyles />
            <PersistEntryPath />
            <Root>
              {children}
              <Widget />
            </Root>
          </LeadModalProvider>
        </SharedStateProvider>
      </LocationProvider>
    </ThemeProvider>
  )
}

const PersistEntryPath = () => {
  const [state, setState] = useSharedState()

  useEffect(() => {
    const loc = location?.pathname

    if (
      [
        '/beleihen/',
        '/beleihen',
        '/flexibel/',
        '/flexibel',
        '/partner/',
        '/partner',
        '/renovieren/',
        '/renovieren',
        '/vorsorge/',
        '/vorsorge',
      ].includes(loc)
    ) {
      setState({ ...state, entryPath: loc })
    }
  }, [])

  return null
}

export default Provider
