import { keyframes } from '@emotion/core'

export const fadeSlideIn = keyframes`
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
`

export const fadeSlideOut = keyframes`
  0% {
    transform: translate(0%);
    opacity: 1;
  }
  100% {
    transform: translate(100%);
    opacity: 0;
  }
`
