export const fetchLocation = async () => {
  const req = await fetch('https://freegeoip.app/json/')
  const location = await req.json()

  // Response:
  // {
  //   ip: "11.111.111.111",
  //   country_code: "AT",
  //   country_name: "Austria",
  //   region_code: "1",
  //   region_name: "Vienna",
  //   city: "Vienna",
  //   zip_code: "1010",
  //   time_zone: "Europe/Vienna",
  //   latitude: 48.123,
  //   longitude: 16.123,
  //   metro_code: 0
  // }

  return location
}
