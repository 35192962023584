import React from 'react'
import { Phone } from 'icons'
import { keyframes } from '@emotion/core'

import { Flex, Box, Icon } from 'components'

const animation = keyframes`
  0% {
    transform: translateX(4px);
  }

  50% {
    transform: translateX(0);
  }
`

const Toggle = ({ onClick, attention }) => {
  return (
    <Flex
      sx={{
        pl: 1,
        pr: 2,
        py: 2,
        height: 40,
        alignItems: 'center',
        bg: 'purple',
        boxShadow: 'md',
        borderRadius: 'default',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        cursor: 'pointer',
        transform: 'translateX(4px)',
        transition: 'transform 100ms ease-out',

        '&:hover, &:active, &:focus': {
          transform: 'translateX(0)',
        },

        ...(attention && {
          animation: `${animation} 250ms ease-out 1250ms`,
          animationIterationCount: '3',
        }),
      }}
      onClick={onClick}
    >
      <Box flexGrow="0" px={2} lineHeight={0}>
        <Icon source={Phone} color="white" size={18} />
      </Box>
    </Flex>
  )
}

export default Toggle
