import { useState, useEffect } from 'react'
import throttle from 'lodash/throttle'

import { isBrowser } from 'utils'

export default function useWindowSize() {
  const [dimensions, setDimenions] = useState({
    width: isBrowser && window.innerWidth,
    height: isBrowser && window.innerHeight,
  })

  useEffect(() => {
    const handleResize = throttle(
      () =>
        setDimenions({
          width: isBrowser && window.innerWidth,
          height: isBrowser && window.innerHeight,
        }),
      150
    )

    isBrowser && window.addEventListener('resize', handleResize)

    return () => {
      isBrowser && window.removeEventListener('resize', handleResize)
    }
  })

  return {
    width: dimensions.width,
    height: dimensions.height,
  }
}
