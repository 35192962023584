import React from 'react'
import {
  Box,
  Flex,
  Text,
  Heading,
  Button as ButtonBase,
  Link,
  Image,
  Card,
} from 'rebass'
import { Grid } from 'theme-ui'

import { Label, Input, Select, Textarea, Radio, Checkbox } from '@rebass/forms'
import Space from '@rebass/space'
import { keyframes } from '@emotion/core'

export const LinkedHeading = ({ linked = true, ...props }) => {
  return <Heading {...props} />

  // FIXME: Issue with umlauts
  // if (!linked || !props.id) return <Heading {...props} />
  //
  // return (
  //   <Heading {...props}>
  //     <Link href={`#${props.id}`}>{props.children}</Link>
  //   </Heading>
  // )
}

export { Grid }
Heading.H1 = (props) => <LinkedHeading variant="styles.h1" as="h1" {...props} />
Heading.H2 = (props) => <LinkedHeading variant="styles.h2" as="h2" {...props} />
Heading.H3 = (props) => <LinkedHeading variant="styles.h3" as="h3" {...props} />
Heading.H4 = (props) => <LinkedHeading variant="styles.h4" as="h4" {...props} />
Heading.H5 = (props) => <LinkedHeading variant="styles.h5" as="h5" {...props} />
Heading.H6 = (props) => <LinkedHeading variant="styles.h6" as="h6" {...props} />

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Button = ({ loading = false, disabled = false, children, ...props }) => {
  return (
    <ButtonBase
      {...(loading && { 'data-loading': true })}
      disabled={disabled}
      {...props}
    >
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Box
            as="svg"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            sx={{
              display: 'block',
              width: 16,
              height: 16,
              animation: `${rotate} 1000ms linear infinite`,
            }}
          >
            <circle
              cx="12"
              cy="12"
              r="10"
              stroke="white"
              strokeWidth="4"
              style={{ opacity: 0.25 }}
            ></circle>
            <path
              fill="white"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              style={{ opacity: 0.75 }}
            ></path>
          </Box>
        </Box>
      )}
      {children}
    </ButtonBase>
  )
}

export { Box, Flex, Text, Heading, Button, Link, Image, Card }
export { Label, Input, Select, Textarea, Radio, Checkbox }
export { Space }

const getSectionSpacing = (s) => {
  switch (s) {
    case 'small':
      return [4, 5]
    case 'large':
      return [6, 11]
    default:
      return [4, 8]
  }
}

export const Root = (props) => {
  return (
    <Flex
      variant="styles.root"
      sx={{
        flexDirection: 'column',
        minHeight: '100vh',
        bg: 'white',

        '> #gatsby-focus-wrapper': {
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        },
      }}
      {...props}
    />
  )
}

export const Container = ({ ...props }) => (
  <Box
    maxWidth="container"
    // px={[3, 4]}
    mx="auto"
    width="100%"
    {...props}
  />
)

export const Section = React.forwardRef(
  ({ spacing, noPaddingTop = false, ...rest }, ref) => {
    return (
      <Box
        ref={ref}
        pt={getSectionSpacing(spacing)}
        pb={getSectionSpacing(spacing)}
        px={[3, 4, null, null]}
        sx={{ position: 'relative' }}
        {...(noPaddingTop && { pt: '0px' })}
        {...rest}
      />
    )
  }
)

Section.Header = (props) => <Box {...props} />
Section.Body = (props) => <Box mt={6} {...props} />
Section.Footer = (props) => <Box mt={6} {...props} />

export const Hr = (props) => <Box variant="hr" my={4} {...props} />

export const Row = ({ mobile, ...props }) => (
  <Flex
    flexDirection={[mobile ? 'row' : 'column', 'row']}
    flexWrap={mobile ? 'nowrap' : 'wrap'}
    mx={[0, -3]}
    // my={[-2, -3, 0]}
    {...props}
  />
)

export const Column = ({ size, narrow, width, ...rest }) => (
  <Box
    py={[2, 3, 0]}
    px={[0, 3]}
    width={Array.isArray(size) ? size : [narrow ? null : 1, size]}
    sx={{
      flexBasis: size || narrow ? null : 0,
      flexGrow: size || narrow ? null : 1,
      flexShrink: size || narrow ? null : 1,
    }}
    {...rest}
  />
)

export const Icon = ({ source: Icon, size, iconProps = {}, ...props }) => {
  return (
    <Box size={size} sx={{ lineHeight: 1, flexShrink: 0 }} {...props}>
      <Icon size={size} {...iconProps} />
    </Box>
  )
}
