// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-beratung-js": () => import("./../../../src/pages/beratung.js" /* webpackChunkName: "component---src-pages-beratung-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-extended-js": () => import("./../../../src/pages/extended.js" /* webpackChunkName: "component---src-pages-extended-js" */),
  "component---src-pages-finanzieren-js": () => import("./../../../src/pages/finanzieren.js" /* webpackChunkName: "component---src-pages-finanzieren-js" */),
  "component---src-pages-finanzierung-js": () => import("./../../../src/pages/finanzierung.js" /* webpackChunkName: "component---src-pages-finanzierung-js" */),
  "component---src-pages-glossar-js": () => import("./../../../src/pages/glossar.js" /* webpackChunkName: "component---src-pages-glossar-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-2-js": () => import("./../../../src/pages/index2.js" /* webpackChunkName: "component---src-pages-index-2-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-teilverkauf-js": () => import("./../../../src/pages/teilverkauf.js" /* webpackChunkName: "component---src-pages-teilverkauf-js" */),
  "component---src-pages-werte-js": () => import("./../../../src/pages/werte.js" /* webpackChunkName: "component---src-pages-werte-js" */),
  "component---src-screens-blog-templates-article-js": () => import("./../../../src/screens/Blog/templates/Article.js" /* webpackChunkName: "component---src-screens-blog-templates-article-js" */),
  "component---src-screens-blog-templates-home-js": () => import("./../../../src/screens/Blog/templates/Home.js" /* webpackChunkName: "component---src-screens-blog-templates-home-js" */),
  "component---src-screens-faq-templates-faq-js": () => import("./../../../src/screens/Faq/templates/Faq.js" /* webpackChunkName: "component---src-screens-faq-templates-faq-js" */),
  "component---src-screens-glossary-term-js": () => import("./../../../src/screens/Glossary/Term.js" /* webpackChunkName: "component---src-screens-glossary-term-js" */),
  "component---src-screens-guide-templates-article-js": () => import("./../../../src/screens/Guide/templates/Article.js" /* webpackChunkName: "component---src-screens-guide-templates-article-js" */),
  "component---src-screens-guide-templates-guide-js": () => import("./../../../src/screens/Guide/templates/Guide.js" /* webpackChunkName: "component---src-screens-guide-templates-guide-js" */),
  "component---src-screens-guide-templates-home-js": () => import("./../../../src/screens/Guide/templates/Home.js" /* webpackChunkName: "component---src-screens-guide-templates-home-js" */)
}

